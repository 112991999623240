import React from 'react';
import {Formik, Form} from "formik";
import {object, string, ref} from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import { registerUser, signInWithGoogle } from "../../../redux/login";
import { registeringSelector } from "../../../redux/login/selectors";
import arrowRight from "../../../img/arrow-right.svg";

const RegisterForm: React.FC = () => {
    const registering = useSelector(registeringSelector);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    return (
        <div>
            <div className="text-sm md:text-lg uppercase text-center mb-2 font-hc tracking-wide text-oswald text-primary font-medium">
                New User Registration
            </div>
            <div className="mt-2 mb-6 flex justify-center">
                {/* @ts-ignore */}
                <Button variant="contained" color="inherit" onClick={() => dispatch(signInWithGoogle(enqueueSnackbar))}>
                    <span className="font-oswald normal-case font-thin text-graydark">
                        or REGISTER WITH GOOGLE
                    </span>
                </Button>
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={object().shape({
                    email: string().email("Please enter a valid email").required("Please enter a valid email"),
                    password: string().required("Please enter a password"),
                    confirmPassword: string().oneOf([ref('password'), null], 'Passwords must match')
                })}
                onSubmit={({email, password}) => {
                    // @ts-ignore
                    dispatch(registerUser(email, password, enqueueSnackbar));
                }}>
                <Form>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="email"
                            label="Email"
                            placeholder="john.smith@gmail.com"
                            CustomComponent={TextField}
                            variant="outlined"
                            fullWidth />
                    </div>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="password"
                            label="Password"
                            type="password"
                            CustomComponent={TextField}
                            variant="outlined"
                            fullWidth />
                    </div>
                    <div className="mb-4">
                        {/* @ts-ignore */}
                        <CustomFormikField
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            CustomComponent={TextField}
                            variant="outlined"
                            fullWidth />
                    </div>
                    {registering ?
                        <div className="flex justify-center">
                            <CircularProgress size={35} />
                        </div> :
                        <>
                            <div className="mt-8 flex justify-center">
                                <Button variant="contained" color="primary" type="submit">
                                    <div className="font-oswald font-light flex items-center md:text-base">
                                        <div>
                                            <span className="font-medium">Register</span> &amp; Continue To Checkout
                                        </div>
                                        <div className="ml-3">
                                            <img src={arrowRight} className="w-5 h-5" alt="Next" />
                                        </div>
                                    </div>
                                </Button>
                            </div>

                        </>
                    }
                </Form>
            </Formik>
        </div>
    );
};

export default RegisterForm;
