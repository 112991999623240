import React, {useCallback, useState} from 'react';
import {
    AppBar,
    Card,
    Toolbar,
    Switch,
    Button,
    IconButton,
    Tooltip
} from "@mui/material";
import { format, addMonths } from "date-fns";
import { useSelector } from "react-redux";

import MembershipTier, { MembershipTierType } from "../membershipTier";
import PaymentOption from "../paymentOption";
import Login from "../login";
import Checkout from "../checkout";
import EarlyBird from "../earlybird";
import {
    checkoutSuccessSelector
} from "../../redux/checkout/selectors";
import {
    earlybirdSubtotalInPennies,
    proratedAmountInPennies,
    proratedSavingsInPennies,
    savingsInPennies
} from "../../utils/money";
import {
    MEMBERSHIP_TIERS,
    MEMBERSHIP_TIER_ELITE,
    STEPS,
    PAYMENT_TYPE_ANNUAL,
    PAYMENT_TYPE_MONTHLY,
    MEMBERSHIP_TIER_SUPPORTING
} from "../../api/data";

import logo from "../../img/nica-logo.svg"
import info from "../../img/info.svg";
import arrowRight from "../../img/arrow-right.svg";
import backBtn from "../../img/back-btn.svg";
import ThankYou from "../thankYou";

const Home: React.FC = () => {
    const [step, setStep] = useState(0);
    const [selectedTier, setSelectedTier] = useState<MembershipTierType>(MEMBERSHIP_TIER_ELITE);
    const [tierQuantity, setTierQuantity] = useState(1);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(PAYMENT_TYPE_ANNUAL);
    const [autoRenew, setAutoRenew] = useState(true);
    const [earlyBirdSelected, setEarlyBirdSelected] = useState(false);
    const checkoutSuccess = useSelector(checkoutSuccessSelector)

    const onLogin = useCallback(() => setStep(step + 1), [step]);

    return (
        <div className="h-full flex flex-col">
            <AppBar position="sticky">
                <Toolbar>
                    <div className="flex relative w-full items-center">
                        <div>
                            <img src={logo} alt="NICA" className="h-6 md:h-8" />
                        </div>
                        <div className="absolute inset-0 flex justify-center font-oswald text-xs md:text-base">
                            JOIN or RENEW
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex-1 bg-graylight p-3 md:p-8 overflow-y-scroll font-oswald">
                {!checkoutSuccess ?
                    <>
                        <div className="uppercase text-primary text-center mb-3 md:mb-6 flex items-center justify-center">
                            {step > 0 &&
                                <div className="mr-2">
                                    <IconButton onClick={() => setStep(step-1)}>
                                        <img src={backBtn} className="w-8 h-8" alt="Back" />
                                    </IconButton>
                                </div>
                            }
                            <div>
                                Step {step + 1} of {STEPS.length}: {STEPS[step]}
                            </div>
                        </div>
                        <div className="flex justify-center">
                            {step === 0 &&
                                <Card className="p-2 md:p-3 w-full md:w-[600px] lg:w-[800px]">
                                    {MEMBERSHIP_TIERS.map((tier, index) => (
                                        <div key={`membership-tier-${index}`} onClick={() => {
                                            setSelectedTier(tier);
                                            setSelectedPaymentOption(PAYMENT_TYPE_ANNUAL);
                                        }}>
                                            <MembershipTier
                                                tier={tier}
                                                selected={selectedTier === tier}
                                                quantity={tierQuantity}
                                                onAdd={() => setTierQuantity(Math.min(MEMBERSHIP_TIER_SUPPORTING.maxQuantity, tierQuantity + 1))}
                                                onSubtract={() => setTierQuantity(Math.max(1, tierQuantity - 1))}
                                            />
                                        </div>
                                    ))}
                                </Card>
                            }
                            {step === 1 &&
                                <div className="w-[600px]">
                                    <Card className="w-full">
                                        <div className="p-3">
                                            <div onClick={() => setSelectedPaymentOption(PAYMENT_TYPE_ANNUAL)}>
                                                <PaymentOption
                                                    name="Prorated Annual Payment"
                                                    description={`Membership valid Today - Dec 31, ${new Date().getFullYear()}`}
                                                    amountInPennies={proratedAmountInPennies(selectedTier)}
                                                    savingsInPennies={proratedSavingsInPennies(selectedTier)}
                                                    selected={selectedPaymentOption === PAYMENT_TYPE_ANNUAL}
                                                    quantity={selectedTier.allowMultiple ? tierQuantity : 1}/>
                                            </div>
                                            <div onClick={() => {
                                                if (selectedTier.price.monthlyInPennies !== undefined) {
                                                    setSelectedPaymentOption(PAYMENT_TYPE_MONTHLY);
                                                    setAutoRenew(true);
                                                }
                                            }}>
                                                <PaymentOption
                                                    name="Monthly Payments"
                                                    description={`Membership valid Today - ${format(addMonths(new Date(), 1), "MMM dd, yyyy")}`}
                                                    amountInPennies={selectedTier.price.monthlyInPennies}
                                                    selected={selectedPaymentOption === PAYMENT_TYPE_MONTHLY}
                                                    quantity={1} />
                                            </div>
                                        </div>
                                        <div className="border-solid border-0 border-t border-graymedium flex py-3 md:py-4 px-5 md:px-7 items-center">
                                            <div className="font-lato text-primary font-bold text-xs md:text-base">
                                                Auto Renew Payments
                                            </div>
                                            <div className="ml-1">
                                                <Tooltip title={`Your membership will be automatically renewed on ${selectedPaymentOption === PAYMENT_TYPE_ANNUAL ? "January 1st of each year" : "the 1st of each month"} at the current price as of that date.`}>
                                                    <img src={info} className="w-4 h-4" alt="Auto Renew More Info" />
                                                </Tooltip>
                                            </div>
                                            <div className="flex-1 flex justify-end">
                                                <Switch checked={autoRenew} onChange={e => {
                                                    if (selectedPaymentOption !== PAYMENT_TYPE_MONTHLY) {
                                                        setAutoRenew(e.target.checked);
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </Card>
                                    {selectedPaymentOption === PAYMENT_TYPE_ANNUAL &&
                                        <EarlyBird
                                            priceInPennies={selectedTier.price.annualInPennies}
                                            subtotalInPennies={earlybirdSubtotalInPennies(selectedTier)}
                                            savingsInPennies={savingsInPennies(selectedTier)}
                                            tierName={selectedTier.name}
                                            earlyBirdSelected={earlyBirdSelected}
                                            setEarlyBirdSelected={setEarlyBirdSelected}
                                            quantity={selectedTier.allowMultiple ? tierQuantity : 1}/>
                                    }
                                </div>
                            }
                            {step === 2 &&
                                <Card className="w-[600px]">
                                    <Login onLogin={onLogin} />
                                </Card>
                            }
                            {step === 3 &&
                                <Checkout
                                    autoRenew={autoRenew}
                                    setAutoRenew={setAutoRenew}
                                    selectedTier={selectedTier}
                                    quantity={selectedTier.allowMultiple ? tierQuantity : 1}
                                    earlyBirdSelected={earlyBirdSelected}
                                    selectedPaymentType={selectedPaymentOption}
                                />
                            }
                        </div>
                        {step < STEPS.length - 1 && step !== 2 &&
                            <div className="flex justify-center mt-4">
                                <Button variant="contained" color="primary" onClick={() => setStep(step + 1)}>
                                    <div className="font-oswald font-light flex items-center">
                                        <div>
                                            <span className="font-medium">Continue</span> to {STEPS[step + 1]}
                                        </div>
                                        <div className="ml-3">
                                            <img src={arrowRight} className="w-5 h-5" alt="Next" />
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        }
                    </> :
                    <ThankYou />
                }

            </div>
        </div>
    )
};

export default Home;
