import { createSlice } from '@reduxjs/toolkit'
import { getLocal, saveLocal } from "../../utils";
// import { logout as eventsLogout } from "../events";

const KEY_LOGGED_IN = "loggedIn";
const KEY_EMAIL = "authEmail";
const KEY_CLAIMS = "claims";

const INITIAL_STATE = {
    authEmail: getLocal(KEY_EMAIL),
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    loggingIn: false,
    loginError: null,
    claims: getLocal(KEY_CLAIMS, true) || {},
    authInitialized: false,
    resetCodeVerified: false,
    verifyEmail: null,
    registering: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        userAuthed(state, action) {
            const { claims } = action.payload;
            saveLocal(KEY_CLAIMS, claims, true);
            saveLocal(KEY_LOGGED_IN, true, false);
            state.claims = claims;
            state.loggingIn = false;
            state.loggedIn = true;
            state.authInitialized = true;
            state.registering = false;
        },
        userUnauthed(state, action) {
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            state.loggedIn = false;
            state.claims = {};
            state.authInitialized = false;
        },
        loggingIn(state, action) {
            state.loggingIn = action.payload;
        },
        loggedIn(state) {
            state.loggedIn = true
        },
        resetCodeVerified(state, action) {
            state.resetCodeVerified = true;
            state.verifyEmail = action.payload;
        },
        registering(state, action) {
            state.registering = action.payload;
        }
    }
});

export const {
    userAuthed, userUnauthed, loggingIn, loggedIn, resetCodeVerified, registering
} = loginSlice.actions;

export default loginSlice.reducer

// CUSTOM THUNK ACTIONS

export const signInWithEmailPassword = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.loginWithEmailPassword(email, password)
            .catch(error => {
                dispatch(legacyAuth(email, password, enqueueSnackbar));
                console.error(error);
            });
    }
);

export const signInWithGoogle = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.loginWithGoogle()
            .catch(error => {
                enqueueSnackbar(error.message, {variant: 'error'})
                console.error(error);
            });
    }
);

export const legacyAuth = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.legacyAuth(email, password)
            .then(result => {
                const token = result.data;
                dispatch(signInWithToken(token, () => {}, enqueueSnackbar))
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const signInWithToken = (token, setStatus, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.loginWithToken(token)
            .then(credentials => {
                dispatch(loggedIn())
            })
            .catch(error => {
                setStatus("wompity womp")
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const signOut = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.logout()
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const initiatePasswordReset = (email, showConfirmation, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loggingIn(true));
        return api.initiatePasswordReset(email)
            .then(() => {
                dispatch(loggingIn(false));
                showConfirmation();
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const verifyResetCode = (code, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.verifyResetPasswordCode(code)
            .then(email => {
                dispatch(resetCodeVerified(email));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const confirmPasswordReset = (code, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        const verifyEmail = getState().login.verifyEmail;
        dispatch(loggingIn(true));
        return api.confirmPasswordReset(code, password)
            .then(email => {
                dispatch(signInWithEmailPassword(verifyEmail, password));
            })
            .catch(error => {
                console.error(error);
                dispatch(loggingIn(false));
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const registerUser = (email, password, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(registering(true));
        return api.registerUser(email, password)
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);