import React, {useEffect, useState} from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";

import LoginForm from "./loginForm";
import ForgotPassword from "./forgotPassword";
import RegisterForm from "./registerForm";
import {claimsSelector, loggedInSelector} from "../../redux/login/selectors";
import {signOut} from "../../redux/login";

import profileImg from "../../img/profile.svg";
import signOutImg from "../../img/signout.svg";
import arrowRight from "../../img/arrow-right.svg";

interface Props {
    onLogin: () => void;
}

const Login: React.FC<Props> = ({ onLogin }) => {
    const [tab, setTab] = useState(0);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const loggedIn = useSelector(loggedInSelector);
    const claims = useSelector(claimsSelector);
    const [initiallyLoggedIn] = useState(loggedIn);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        // Only trigger this callback after the user has logged in on this screen
        if (loggedIn && !initiallyLoggedIn) {
            onLogin();
        }
    }, [loggedIn, initiallyLoggedIn, onLogin]);

    return (
        <div>
            {loggedIn ?
                <div className="pt-3 pb-4 px-4 md:px-6">
                    <div className="text-center font-lato text-graydark pb-3 md:border-solid md:border-0 md:border-b md:border-graymedium text-xs md:text-base">
                        It's good to see you!
                    </div>
                    <div className="py-3 md:pt-4 md:pb-0 flex justify-center md:justify-start items-center bg-graylight md:bg-transparent rounded">
                        <div className="rounded-full w-8 h-8 md:w-12 md:h-12 bg-green flex justify-center items-center">
                            <img src={profileImg} alt="Profile" className="w-6 h-6" />
                        </div>
                        <div className="font-oswald pl-3 font-medium text-primary flex items-center md:flex-1 text-sm md:text-base">
                            {claims.email}
                        </div>
                        <div className="hidden md:block">
                            {/* @ts-ignore */}
                            <Button variant="text" onClick={() => dispatch(signOut(enqueueSnackbar))}>
                                <div className="flex items-center">
                                    <span className="lato text-secondary normal-case">Sign Out</span> <img src={signOutImg} className="ml-1 w-4 h-4" alt="Sign Out" />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className="md:hidden flex justify-center mt-3">
                        {/* @ts-ignore */}
                        <Button variant="text" onClick={() => dispatch(signOut(enqueueSnackbar))}>
                            <div className="flex items-center">
                                <span className="lato text-secondary text-xs md:text-base normal-case">Sign Out</span> <img src={signOutImg} className="ml-1 w-4 h-4" alt="Sign Out" />
                            </div>
                        </Button>
                    </div>
                    <div className="flex justify-center mt-4">
                        <Button variant="contained" color="primary" onClick={() => onLogin()}>
                            <div className="font-oswald font-light flex items-center">
                                <div>
                                    <span className="font-medium">Continue</span> To Checkout
                                </div>
                                <div className="ml-3">
                                    <img src={arrowRight} className="w-5 h-5" alt="Next" />
                                </div>
                            </div>
                        </Button>
                    </div>
                </div> :
                <div>
                    <div className="border-0 border-solid border-b border-graylight">
                        <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example" variant="fullWidth" color="secondary">
                            <Tab label={<span className="font-oswald">Sign In</span>} />
                            <Tab label={<span className="font-oswald">Register</span>} />
                        </Tabs>
                    </div>
                    <div className="px-4 md:px-14 pb-6 pt-4">
                        {tab === 0 ?
                            (!showForgotPassword ?
                                <LoginForm setShowForgotPassword={setShowForgotPassword} /> :
                                <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
                            ) :
                            <div>
                                <RegisterForm />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export default Login;
